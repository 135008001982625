


<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">用户充值汇总</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <!-- <el-row :gutter="10">
              <el-col v-show="isadmin" :span="8">
                <el-form-item label="运营商:">
                  <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                    <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row> -->
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="查询条件:">
                <el-select
                  v-model="FormData.dateType"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  @change="chengeType"
                >
                  <el-option :value="0" label="月份" />
                  <el-option :value="1" label="日期" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="7">
              <el-form-item label="时间:" class="time_pick">
                <el-date-picker
                  v-if="FormData.dateType == 0"
                  style="height: 40px; width: 100%"
                  v-model="FormData.month"
                  type="month"
                  @change="changeMonth"
                  placeholder="选择月份"
                >
                </el-date-picker>
                <el-date-picker
                  v-if="FormData.dateType == 1"
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  align="right"
                  range-separator=" - "
                  start-placeholder="请选择开始日期"
                  end-placeholder="请选择结束日期"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <el-col :span="1" style="margin-left:40px">
              <Data2Excel api='/report/user_charge_statistics' :data="searchParam" fileName="充值汇总"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="540px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="id" type="index" width="60" /> -->
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" />
          <el-table-column
            label="充值总金额(元)"
            prop="sum_charge_amount"
            :formatter="rounding"
          />
          <el-table-column label="充值次数" prop="frequency" />
          <!-- <el-table-column label="所属运营商" prop="username" /> -->
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :small="small"
          layout="total, slot, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <template #default>
            <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
          </template>
        </el-pagination>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { user_charge_statistics, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import Data2Excel from "@/component/Data2Excel.vue";
import ProvinceCityCountry from "../../assets/json/address";
export default {
  name: "InvestCollect",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
        operator_id: sessionStorage.getItem("userID"),
        dateType: 0,
      },
      users: [],
      tableData: [],
      isadmin: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      loading: false,
      provinceData: ProvinceCityCountry.address,
      searchParam: {}
    });
    const changeMonth = (value) => {
      if (value != null && value != undefined && value != "") {
        let date = new Date(value);
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let day = new Date(year, month, 0);
        data.FormData.orderTime[0] = year + "-" + month + "-01";
        data.FormData.orderTime[1] = year + "-" + month + "-" + day.getDate();
      } else {
        data.FormData.orderTime[0] = "";
        data.FormData.orderTime[1] = "";
      }
      // console.log(endTime ,'endTime ');
    };
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const chengeType = () => {
      data.FormData.orderTime = ["", ""];
    };
    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      data.searchParam = {
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        phone: data.FormData.phone,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page: data.currentPage,
      };

      data.loading = true;
      user_charge_statistics(data.searchParam).then((res) => {
        console.log(res, "000999888");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
            data.tableData.forEach((a)=>{
              a.sum_charge_amount = Number(a.sum_charge_amount )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    // const getTime = () => {
    //     var aData = new Date();
    //     const nowdate = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
    //     data.FormData.orderTime[0] = nowdate
    //     data.FormData.orderTime[1] = nowdate
    //     console.log(data.FormData.orderTime,'data.order_timedata.order_time');
    //   }
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
    };
    onMounted(() => {
      getUsername();
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      rounding,
      changeMonth,
      getList,
      getUsername,
      handleCurrentChange,
      chengeType,
    };
  },
};
</script>
<style scoped>
.bottomDiv {
  height: 100%;
}
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
  width: 100%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
</style>